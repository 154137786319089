import React from 'react';
import { useParams, Link } from 'react-router-dom';
import blogs from '../data/blogs';
import '../CSS/BlogDetail.css';

const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogs.find(blog => blog.id === parseInt(id));

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div className='BlogDetailWrap'>
        <div className="BlogDetail">
      <img src={blog.image} alt={blog.title} />
      <h1>{blog.title}</h1>
      <p>{blog.content}</p>
      <Link onClick={()=>window.history.back()} className="BackToBlogs">Back to Posts</Link>
    </div>
    </div>
  );
}

export default BlogDetail;

import React from 'react';
import '../CSS/Gallery.css';
import g11 from "../Images/g11.jpeg"
import g12 from "../Images/g12.jpeg"
import g13 from "../Images/g13.jpeg"
import g14 from "../Images/g14.jpeg"
import g15 from "../Images/g15.jpeg"
import g16 from "../Images/g16.jpeg"
import g17 from "../Images/g17.jpeg"
import g18 from "../Images/g18.jpeg"

const photos = [
  { id: 1, src: g11, alt: 'Photo 1' },
  { id: 2, src: g12, alt: 'Photo 2' },
  { id: 3, src: g13, alt: 'Photo 3' },
  { id: 4, src: g14, alt: 'Photo 4' },
  { id: 5, src: g15, alt: 'Photo 5' },
  { id: 6, src: g16, alt: 'Photo 6' },
  { id: 7, src: g17, alt: 'Photo 7' },
  { id: 8, src: g18, alt: 'Photo 8' },
  // Add more photos as needed
];

const Gallery = () => {
  return (
    <div className='GalleryWrap'>
        <h1>GALLERY</h1>
        <div className="gallery">
        
        {photos.map(photo => (
          <div key={photo.id} className="gallery-item">
            <img src={photo.src} alt={photo.alt} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;

import React from 'react';
import '../CSS/Career.css'; // Import CSS file for styling
import { FaBox, FaBoxOpen, FaDirections, FaEnvelope, FaHeart, FaIdCard, FaProcedures } from 'react-icons/fa';
import { FaSquareSteam } from 'react-icons/fa6';
import Swal from 'sweetalert2'

const Career = () => {

    const handleAlert =()=>{
        Swal.fire({icon:"success",text:"coming soon...",showConfirmButton:false,timer:2000})
    }

  return (
    <div className='CareerWrap'>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>CAREER</h1>
          <p>Join us and make a difference</p>
          {/* <a href="#jobs" className="scroll-down-btn">View Jobs</a> */}
        </div>
      </section>

      <div className='CareerDisClaimer'>
          <p>Careers in CL are focused on the strategic development of talent in line with the key business imperative of ensuring continuity through our key source of competitive advantage.</p>
      </div>

      <div className='WhoWeAreLookingFor'>
          <h1>Who We Are Looking For</h1>
          <p>A true CL staff lives our values, uses success to achieve positive change, always strives to make things better and delivers results in line with our business objectives.<br/>
          We would usually recruit staff by head-hunting potentially suitable candidates. At other times, we place vacancy advertisements in the press.<br/>
          Should you wish to pursue a career with us, you may watch out for our occasional vacancy publication. In the alternative, send a CV to careers@happymanbitters.com and we will review it and possibly, keep it in view for consideration when an appropriate vacancy crops up.<br/>
          While we will make every attempt to acknowledge the receipt of such CVs, we are unfortunately, not able to acknowledge the receipt of every CV received.</p>
      </div>

      {/* About Working Here Section */}
      <section className="about-section">
      <h1>About Working with Happy Man</h1>
        <div className="container">
          
          <div className="CareerCard">
            <FaBoxOpen className="CareerIcon"/>
            <div className="text">
              <h3>Diverse Opportunities:</h3>
              <p>HAPPY MAN Company offers diverse roles in environmental consulting and real estate, providing employees with various career paths and growth opportunities.</p>
            </div>
          </div>

          

          <div className="CareerCard">
            <FaBox className="CareerIcon"/>
            <div className="text">
              <h3>Professional Development:</h3>
              <p>HAPPY MAN Company fosters employee growth through training and mentorship, empowering individuals to enhance their skills and excel in their careers.</p>
            </div>
          </div>

         

          <div className="CareerCard">
            <FaDirections className="CareerIcon"/>
            <div className="text">
              <h3>Innovation: </h3>
              <p>At HAPPY MAN Company, we embrace innovation and creativity, encouraging employees to bring forward fresh ideas and solutions.</p>
            </div>
          </div>

          <div className="CareerCard">
            <FaHeart className="CareerIcon"/>
            <div className="text">
              <h3>Wellbeing of Our Employee:</h3>
              <p>At HAPPY MAN Company, we prioritize health and happiness by offering competitive benefits, flexible work arrangements, and initiatives that support work-life balance, ensuring the well-being of our employees.</p>
            </div>
          </div>
          {/* Repeat the CareerCard structure for the remaining points */}
          {/* Two CareerCards in one line */}
        </div>
      </section>

      {/* Background Image Section */}
      <section className="bg-image-section"></section>

      {/* Open Positions Section */}
      <section id="jobs" className="open-positions">
  <h1>Open Positions</h1>
  <div className="container">
    
    <div className="job">
      <h3>Production Supervisor</h3>
      <p>Oversee the production process of our bitter drinks and liquors, ensuring quality control, managing production schedules, and coordinating with the production team.</p>
      <button onClick={handleAlert}>Apply</button>
    </div>

    <div className="job">
      <h3>Quality Assurance Specialist</h3>
      <p>Conduct quality assessments, develop and implement quality control procedures, and ensure all products meet industry standards and company specifications.</p>
      <button onClick={handleAlert}>Apply</button>
    </div>

    <div className="job">
      <h3>Marketing Manager</h3>
      <p>Develop and execute marketing strategies, manage campaigns, and enhance brand presence in the market to drive sales of our bitter drinks and liquors.</p>
      <button onClick={handleAlert}>Apply</button>
    </div>

    <div className="job">
      <h3>Research and Development Specialist</h3>
      <p>Innovate and develop new products, improve existing formulas, and collaborate with the production team to bring new ideas to life.</p>
      <button onClick={handleAlert}>Apply</button>
    </div>

    <div className="job">
      <h3>Sales Representative</h3>
      <p>Engage with clients, build relationships, and drive sales by promoting our range of bitter drinks and liquors to retailers and distributors.</p>
      <button onClick={handleAlert}>Apply</button>
    </div>
    
  </div>
</section>

    </div>
  );
}

export default Career;

import React from 'react'
import bottle1 from "../Images/b1 (1).png"
import bottle2 from "../Images/b1 (2).png"
import "../CSS/SpinningBottles.css"

const SpiningBottles = () => {
  return (
    <div className='SpinningBottles'>
        <img src={bottle2} alt="bottle1"/>
        <h1>BE A HAPPY MAN TODAY!</h1>
        <img src={bottle2} alt="bottle2"/>

    </div>
  )
}

export default SpiningBottles


import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeroPic1 from "../Images/HeroPic1.jpeg";
import HeroPic2 from "../Images/HeroPic2.jpeg";
import HeroPic3 from "../Images/HeroPic3.jpeg";
import brandfoot from "../Images/brandfoot2.png"
import "../CSS/Hero.css";
import b1 from "../Images/b1 (1).png"
import b2 from "../Images/b1 (2).png"
import b3 from "../Images/b1 (3).png"
import b4 from "../Images/b1 (4).png"

const OurBrandProducts = () => {
  return (
    <div>
      <div className='Hero'>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={2000}
      >
        {/* <div>
          <img src={HeroPic1} alt="HeroPic1" />
        </div>
        <div>
          <img src={HeroPic2} alt="HeroPic2" />
        </div> */}
        <div>
          <img src={HeroPic3} alt="HeroPic3" />
        </div>
      </Carousel>
      
    </div>
    <div className="carousel-container">
    <h1>OUR BRAND</h1>
    <div className="carousel">
     <img src={b1} alt="b1"/>
     <img src={b2} alt="b1"/>
     <img src={b3} alt="b1"/>
     <img src={b4} alt="b1"/>
    </div>
    
    <p><strong>Product Description:</strong> Happy Man Bitters is a premium herbal liqueur produced in Ghana by Charger Limited. Our bitters are crafted with the finest quality ingredients, including Khaya Senegalensis, Mondia White, Nauclea Ethano, and water, ensuring a unique and refreshing taste. With an alcohol content of 40%, our product promises not only quality but also an invigorating experience. Suitable for adults aged 18 and above, Happy Man Bitters is a perfect blend of tradition and excellence. Be a Happy Man and indulge in the finest herbal liqueur that brings joy and satisfaction.</p>
    <button onClick={()=>window.history.back()}>Back </button>
  </div>
  <Carousel
      showArrows={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      interval={2000}
    >
      {/* <div>
        <img src={HeroPic1} alt="HeroPic1" />
      </div>
      <div>
        <img src={HeroPic2} alt="HeroPic2" />
      </div> */}
      <div>
        <img src={brandfoot} alt="HeroPic3" />
      </div>
    </Carousel>
    </div>
  );
}

export default OurBrandProducts;


import React, { useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import "../CSS/Header.css"
import Logo from "../Images/happyman Logo design.jpg"
import { FaHome, FaProductHunt, FaStore, FaUserAltSlash, FaUsers } from 'react-icons/fa'
import { FaBurger, FaMessage } from 'react-icons/fa6'
import { useRef,useEffect } from 'react'



const Header = () => {
    const [menuSwitch,SetMenuSwitch]=useState(0)
    const navigate = useNavigate()
    const location =useLocation()
    const [mobileMenuSwitch, SetMobileMenuSwitch]=useState(false)



// handling click away
const menuRef=useRef();
useEffect(()=>{
   const handleClickOutside = (event)=>{
      if(menuRef.current&&!menuRef.current.contains(event.target)){
         SetMobileMenuSwitch(false)
        
      }
      
   }
   document.addEventListener('mousedown',handleClickOutside)
      return ()=>{
         document.removeEventListener('mousedown',handleClickOutside)
      }
},[])




  return (
    <div className='Header'>
      <img src={Logo} alt="Logo" onClick={()=>navigate("/")}/>
      <div className='HeaderRight'>
        <NavLink to={"/"} className={location.pathname==="/"?"MenuActive":"Menu"} onClick={()=>SetMenuSwitch(0)}>HOME</NavLink>
        <NavLink to={"/aboutus"} className={location.pathname==="/aboutus"?"MenuActive":"Menu"} onClick={()=>SetMenuSwitch(1)}>ABOUT US</NavLink>
        <NavLink to={"/ourbrandproducts"} className={location.pathname==="/ourbrandproducts"?"MenuActive":"Menu"} onClick={()=>SetMenuSwitch(2)}>OUR BRAND PRODUCTS</NavLink>
        <NavLink to={"/ourstore"} className={location.pathname==="/ourstore"?"MenuActive":"Menu"} onClick={()=>SetMenuSwitch(4)}>OUR STORE</NavLink>
        <NavLink to={"/career"} className={location.pathname==="/career"?"MenuActive":"Menu"} onClick={()=>SetMenuSwitch(4)}>CAREER</NavLink>
        <NavLink to={"/contactus"} className={location.pathname==="/contactus"?"MenuActive":"Menu"} onClick={()=>SetMenuSwitch(3)}>CONTACT US</NavLink>


        <NavLink to={"/"} className={location.pathname==="/"?"MenuActive2":"Menu2"} onClick={()=>SetMenuSwitch(0)}><FaHome/></NavLink>
        <NavLink to={"/aboutus"} className={location.pathname==="/aboutus"?"MenuActive2":"Menu2"} onClick={()=>SetMenuSwitch(1)}><FaUsers/></NavLink>
        <NavLink to={"/ourbrandproducts"} className={location.pathname==="/ourbrandproducts"?"MenuActive2":"Menu2"} onClick={()=>SetMenuSwitch(2)}><FaProductHunt/></NavLink>
        <NavLink to={"/ourstore"} className={location.pathname==="/ourstore"?"MenuActive2":"Menu2"} onClick={()=>SetMenuSwitch(4)}><FaStore/></NavLink>
        <NavLink to={"/career"} className={location.pathname==="/career"?"MenuActive2":"Menu2"} onClick={()=>SetMenuSwitch(4)}>C</NavLink>
        <NavLink to={"/contactus"} className={location.pathname==="/contactus"?"MenuActive2":"Menu2"} onClick={()=>SetMenuSwitch(3)} id="extraResponse"><FaMessage/></NavLink>
        

        <FaBurger className='Burger' onClick={()=>SetMobileMenuSwitch(!mobileMenuSwitch)}/>
        {mobileMenuSwitch&&<div className='MobileMenu3' ref={menuRef}>
        <NavLink to={"/"} className={location.pathname==="/"?"MenuActive3":"Menu3"} onClick={()=>SetMenuSwitch(0)}>HOME</NavLink>
        <NavLink to={"/aboutus"} className={location.pathname==="/aboutus"?"MenuActive3":"Menu3"} onClick={()=>SetMenuSwitch(1)}>ABOUT US</NavLink>
        <NavLink to={"/ourbrandproducts"} className={location.pathname==="/ourbrandproducts"?"MenuActive3":"Menu3"} onClick={()=>SetMenuSwitch(2)}>OUR BRAND PRODUCTS</NavLink>
        <NavLink to={"/ourstore"} className={location.pathname==="/ourstore"?"MenuActive3":"Menu3"} onClick={()=>SetMenuSwitch(4)}>OUR STORE</NavLink>
        <NavLink to={"/career"} className={location.pathname==="/career"?"MenuActive3":"Menu3"} onClick={()=>SetMenuSwitch(4)}>CAREER</NavLink>
        <NavLink to={"/contactus"} className={location.pathname==="/contactus"?"MenuActive3":"Menu3"} onClick={()=>SetMenuSwitch(3)}>CONTACT US</NavLink>

        </div>}
        

      </div>

    </div>
  )
}

export default Header

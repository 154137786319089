import React from 'react';
import "../CSS/LandingPageAbout.css"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import happyManLogo from "../Images/happyman Logo design.jpg";
import { FaWineBottle } from 'react-icons/fa';
import productImage from '../Images/bottle and cup.png';
import productImage2 from '../Images/bottle and cup2.png';
import productImage3 from "../Images/b1 (2).png"
import { useNavigate } from 'react-router-dom';
// import founderImage from './images/founderImage.png';
import CEOPIC from "../Images/CEO PIC.jpeg"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const LandingPageAbout=()=> {
    const navigate = useNavigate()
  return (
    <div className="LandingPageAboutWrap">
      <header className="LandingPageAboutHeader">
        {/* <img src={happyManLogo} alt="Happy Man Beverages Logo" className="LandingPageAboutLogo" /> */}
        <h1 style={{textAlign:"center"}}>About Happy Man Beverages</h1>
      </header>
      
      <section className="overview">
        <h2>Overview</h2>
        <p>
          Happy Man Beverages, a subsidiary of Charger Limited, is a premier beverage company founded in 2013 by Dr. Emmanuel Borkety in Accra, Ghana. The company is renowned for its commitment to producing high-quality, natural beverages that blend traditional African herbs and roots with modern brewing techniques.
        </p>
      </section>
      
      <section className="LandingPageAboutProducts">
        <h2>Products</h2>
        <div className="LandingPageAboutProduct">
            <div className='LandingPageAboutProductImageWrap'>
            <img src={productImage} alt="Happy Man Bitters" className="LandingPageAboutProductImage" />
            <img src={productImage3} alt="Happy Man Bitters" className="LandingPageAboutProductImage" />
            <img src={productImage2} alt="Happy Man Bitters" className="LandingPageAboutProductImage" />
            </div>
          
          <div className="LandingPageAboutProductDetails">
            <h3 style={{color:"green"}}>Happy Man Bitters</h3>
            <p>
              A natural African herbal and roots preparation.
            </p>
            <h4 style={{color:"green"}}>Production Process</h4>
            <p>
              Utilizes a premium and healthy production process, emphasizing first-class quality ingredients sourced from thick African forest and local farmers in Ghana. The production process includes meticulous selection, cleaning, and processing of traditional African herbs and roots to ensure the highest quality and potency of the final product. The company follows strict quality control measures and adheres to international production standards to guarantee the safety and consistency of Happy Man Bitters.
            </p>
          </div>
        </div>
      </section>
      
      <section className="market-presence">
        <h2 style={{color:"green"}}>Market Presence</h2>
        <p>
          Happy Man Beverages established a strong presence in the local Ghanaian and African market and has expanded its reach to Europe, USA and Asian countries. The company has garnered a loyal customer base that appreciates the unique flavors and health benefits of its products. Happy Man Bitters, in particular, has gained popularity for its natural ingredients and traditional brewing methods.
        </p>
      </section>
      
      <section className="mission-values">
        <h2 style={{color:"green"}}>Mission and Values</h2>
        <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={3000} infinite>
          <div className="LandingPageAboutCarousel-item">
            <p>Happy Man Beverages is dedicated to promoting African culture and heritage through its premium beverages.</p>
          </div>
          <div className="LandingPageAboutCarousel-item">
            <p>The company is committed to sustainability, ethical sourcing, and community development.</p>
          </div>
          <div className="LandingPageAboutCarousel-item">
            <p>Happy Man Beverages is dedicated to promoting African culture and heritage through its premium beverages.</p>
          </div>
        </Carousel>
      </section>

      <div className='FounderWrap'>
        <img src={CEOPIC} alt="founderphoto"/>
        <p>Dr. Emmanuel Borkety, the founder, is passionate about preserving traditional African recipes and sharing them with the world in a modern and innovative way.</p>
      </div>
      
      <section className="future-outlook">
        <h2 style={{color:"green"}}>Future Outlook</h2>
        <p>
          With a growing demand for natural and traditional beverages worldwide, Happy Man Beverages is poised for further expansion and success. The company continues to invest in research and development to introduce new products and flavors while maintaining its commitment to quality and authenticity. Happy Man Beverages aims to become a leading player in the global beverage industry, while staying true to its African roots and values.
        </p>
      </section>
      
      <footer className="LandingPageAboutFooter">
        <p><FaWineBottle/> Be a Happy Man Today !!!</p>
      </footer>
    </div>
  );
}

export default LandingPageAbout;

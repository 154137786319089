import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../CSS/ContactUs.css';
import Logo from '../Images/happyman Logo design.jpg';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await axios.post('https://backendhappymanbeverages.onrender.com/api/contact', formData);
      setIsSubmitting(false);

      if (response.data.status === 'success') {
        Swal.fire('Success', 'Your message has been sent and we shall contact you', 'Thanks');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setError(response.data.message);
        Swal.fire('Error', response.data.message, 'error');
      }
    } catch (error) {
      setIsSubmitting(false);
      setError('An error occurred while sending the message.');
      Swal.fire('Error', 'An error occurred while sending the message.', 'error');
    }
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div className="ContactFormWrap">
      <div className="contact-form-container">
        <h2>Contact Us</h2>
        <img src={Logo} alt="Logo" />
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">
            {isSubmitting ? (
              <ClipLoader color={"#ffffff"} loading={isSubmitting} css={override} size={15} />
            ) : (
              "Submit"
            )}
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        <div className="footer-contact" style={{ textAlign: 'center', alignItems: 'center', color: 'green' }}>
          <p>Address: Teshie Nungua Estate, <br />
          Block 8, Plot 32-45 Ankwa Dobro,<br />
          Nsawam Adoagyiri, Eastern Region, Ghana.<br />
          P.O. BOX TN 155,
          </p>
          <p>Email: Office@happymanbeverages.com</p>
          <p>Phone: +234 705 997 9306</p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

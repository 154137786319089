import React from 'react';
import '../CSS/Footer.css';
import {Link, useLocation} from 'react-router-dom'
import Logo1 from "../Images/happyman Logo design.jpg"
import { FaCcMastercard, FaCcVisa, FaFacebook, FaInstagram, FaTwitter, FaWhatsapp,} from 'react-icons/fa';
import visaCardImg from "../Images/visa card image.png"
import masterCardImg from "../Images/master card image.png"
import paypalImg from "../Images/paypal image.png"

const Footer = () => {
  const location = useLocation()
  return (
    <footer className={location.pathname==="/userlogin"||location.pathname==="/usersignup"||location.pathname==="/forgotpassword"||location.pathname.includes("/resetpassword")||location.pathname.includes("/verify")?"footerdisappear":"footer"}>
      <div className="footer-container">
        
        <div className="footer-contact">
     
          <img src={Logo1} alt="logo1" style={{width:"50px",height:"50px",marginBottom:"10px",borderRadius:"50%"}}/>

          <h4>ABOUT HAPPY MAN</h4>

          <p style={{fontSize:"0.8rem"}}>As a company, we continuously make use <br/>
          of research and development as well as the <br/>
          latest technology with local/traditional recipes<br/> 
          in our production process and also employ <br/>
          the best technology in the delivery of our<br/> services. <br/><br/>

          Our sand and stone haulage company employs  <br/>
          the best of human resources and provides <br/>
          quality materials, for construction purposes.</p>
  
        </div>
        <div className="footer-links">
          <h4>Quick Links</h4>
          <Link to={"/"} className="footer-link">Home</Link>
          <Link to={"/aboutus"} className="footer-link">About us</Link>
          <Link to={"/ourbrandproducts"} className="footer-link">Our Brand Products</Link>
          <Link to={"/ourstore"} className="footer-link">Our Store</Link>
          <Link to={"/career"} className="footer-link">Career</Link>
          <Link to={"/contactus"} className="footer-link">Contact us</Link>
       
        </div>
        <div className="footer-contact">
     

          <h4 style={{textDecoration:"underline"}}>Contact</h4>
          <p>Address: Teshie Nungua Estate, <br/>
          Block 8, Plot 32-45 Ankwa Dobro,<br/>
           Nsawam Adoagyiri, Eastern Region, Ghana.<br/>
           P.O. BOX TN 155,
           </p>

          <p>Email: Office@happymanbeverages.com</p>
          <p>Phone: +234 705 997 9306</p>
          {/* <p>Phone: +233 54 827 6687</p> */}
        </div>
        <div className="footer-payment">
          <h4>Payment Methods:</h4>
          <ul>
            <img src={visaCardImg} alt="paymentMethod" onClick={()=>window.open(`https://www.visa.com.ng/pay-with-visa/promotions/olympics-paris-2024.html?gad_source=1&gclid=Cj0KCQjwsuSzBhCLARIsAIcdLm6zBk2qZjr6TqVIt4NCJ95P1B-x1OinDI9Ja4jlLckfC7Jw-gM5Y9waAnIOEALw_wcB`,"_blank")}/><br/>
<img src={masterCardImg} alt="paymentMethod" onClick={()=>window.open(`https://www.mastercard.com/global/en.html`,"_blank")}/><br/>
            <img src={paypalImg} alt="paymentMethod" onClick={()=>window.open(`https://www.paypal.com/ng/home`,"_blank")}/>
            {/* <p> <FaCcVisa/> Visa</p> */}
            {/* <p><FaCcMastercard/> Mastercard</p> */}
            {/* <li>PayPal</li> */}
          </ul>
        </div>
        <div className="footer-social">
          <a href="https://web.facebook.com/57HappymanBitters" className="social-link"><FaFacebook/></a>
          <a href="https://wa.me/2347059979306" class="social-link"><FaWhatsapp/></a>
          {/* <a href="https://twitter.com" className="social-link"><FaTwitter/></a> */}
          <a href="https://instagram.com" className="social-link"><FaInstagram/></a>
          
        </div>
      </div>
      <div className='CopyRight'><p>© Copyright {new Date().getFullYear()} Happy Man Bitters. All Rights Reserved</p>
        <p style={{fontSize:"0.7rem",color:"rgba(255,255,255,0.6)"}}><span style={{color:"rgba(0,255,0,0.7)"}}>POWERED BY</span> ELEXDON DIGITAL TECHNOLOGIES LIMITED</p>
      </div>
      

    </footer>
  );
};

export default Footer;

// src/OurStore.js
import React from 'react';
import '../CSS/OurStore.css';
import b1 from "../Images/b1 (1).png"
import b2 from "../Images/b1 (2).png"
import b3 from "../Images/b1 (3).png"
import b4 from "../Images/b1 (4).png"
import { useNavigate } from 'react-router-dom';

const products = [
    {
      id: 1,
      name: 'Happy Man Bitters Original',
      description: 'A premium herbal liqueur with a unique and refreshing taste.',
      price: '$100',
      image: b1,
    },
    {
      id: 2,
      name: 'Happy Man Bitters Extra Strength',
      description: 'An invigorating herbal liqueur with a stronger kick.',
      price: '$150',
      image: b2,
    },
    {
      id: 3,
      name: 'Happy Man Bitters Light',
      description: 'A lighter version of our original herbal liqueur for a smoother taste.',
      price: '$180',
      image: b3,
    },
    {
      id: 4,
      name: 'Happy Man Bitters Premium Gold',
      description: 'Our exclusive premium herbal liqueur with a luxurious taste.',
      price: '$120',
      image: b4,
    },
    {
      id: 5,
      name: 'Happy Man Bitters Original',
      description: 'A premium herbal liqueur with a unique and refreshing taste.',
      price: '$100',
      image: b1,
    },
    {
      id: 6,
      name: 'Happy Man Bitters Extra Strength',
      description: 'An invigorating herbal liqueur with a stronger kick.',
      price: '$150',
      image: b2,
    },
    {
      id: 7,
      name: 'Happy Man Bitters Light',
      description: 'A lighter version of our original herbal liqueur for a smoother taste.',
      price: '$180',
      image: b3,
    },
    {
      id: 8,
      name: 'Happy Man Bitters Premium Gold',
      description: 'Our exclusive premium herbal liqueur with a luxurious taste.',
      price: '$120',
      image: b4,
    },
  ];

const OurStore = () => {
    const navigate=useNavigate()
    
    return (
    <div className='OurStoreWrap'>
        <div className="store-container">
      <h2>OUR STORE</h2>
      <div className="product-list">
        {products.map(product => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} className="product-image" />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p className="product-price">{product.price}</p>
            <button onClick={()=>navigate("/contactus")}>Order Now</button>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default OurStore;

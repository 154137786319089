import Logo from "../Images/logo1.jpeg";
import blog1 from "../Images/blog1.png";
import blog3 from "../Images/blog3.png";

const blogs = [
    {
        id: 1,
        title: "The Benefits of Bitter Drinks",
        snippet: "Bitter drinks have been consumed for centuries for their various health benefits...",
        content: `
        Bitter drinks have been consumed for centuries for their various health benefits. These include aiding digestion, improving liver function, and more. 

        **1. Aiding Digestion:** Bitter compounds stimulate the production of saliva and gastric juices, which can improve digestion and help break down food more efficiently. This can reduce bloating and discomfort after meals.

        **2. Improving Liver Function:** Many bitter drinks contain herbs known to support liver health, such as dandelion root and milk thistle. These herbs can help detoxify the liver and promote its proper function.

        **3. Reducing Sugar Cravings:** Regular consumption of bitter drinks can help reduce sugar cravings by balancing blood sugar levels and reducing the desire for sweet foods.

        **4. Boosting Immune System:** Some bitter drinks contain antioxidants and other compounds that can support the immune system, helping the body to fend off infections and illnesses.

        In conclusion, incorporating bitter drinks into your diet can offer a variety of health benefits, from improved digestion to better liver health. Enjoy a glass of Happy Man Bitters and experience these benefits for yourself.
        `,
        image: blog1
    },
    {
        id: 2,
        title: "How to Enjoy Happy Man Bitters",
        snippet: "Happy Man Bitters can be enjoyed in various ways. Here are some suggestions...",
        content: `
        Happy Man Bitters can be enjoyed in various ways. Here are some suggestions:

        **1. Straight Shots:** For those who enjoy the pure, unadulterated taste of bitters, straight shots are the way to go. Pour a small amount into a shot glass and drink it as is.

        **2. Mixed with Beverages:** Happy Man Bitters can be mixed with your favorite beverages. Try adding a splash to soda water, tonic water, or even juice for a unique flavor twist.

        **3. In Cocktails:** Bitters are a common ingredient in many classic cocktails. Add Happy Man Bitters to your favorite cocktail recipes for an extra layer of complexity and flavor. Some popular choices include Old Fashioned, Manhattan, and Negroni.

        **4. As a Digestif:** Enjoy Happy Man Bitters as a digestif after a meal. The bitter compounds can help stimulate digestion and provide a satisfying end to your dining experience.

        **5. Cooking Ingredient:** Bitters can also be used as a cooking ingredient. Add a few drops to sauces, marinades, or even desserts to enhance the flavor.

        No matter how you choose to enjoy Happy Man Bitters, you're sure to appreciate the rich, complex flavors and the health benefits they offer.
        `,
        image: Logo
    },
    {
        id: 3,
        title: "The History of Bitter Drinks",
        snippet: "Bitter drinks have a rich history that dates back to ancient times...",
        content: `
        Bitter drinks have a rich history that dates back to ancient times. From traditional medicinal uses to modern-day enjoyment, learn about the journey of bitter drinks through the ages:

        **1. Ancient Times:** The use of bitter herbs and botanicals dates back thousands of years. Ancient Egyptians, Greeks, and Romans used bitters in their medicinal practices to aid digestion and treat various ailments.

        **2. Middle Ages:** During the Middle Ages, bitters were commonly used by monks who created herbal tonics and elixirs. These early recipes often combined bitter herbs with alcohol to create potent medicinal drinks.

        **3. 18th and 19th Centuries:** Bitters became widely popular in the 18th and 19th centuries, especially in Europe and America. Apothecaries and pharmacists sold bitters as cure-alls for a range of health issues. This period also saw the birth of many commercial bitters brands that are still known today.

        **4. Prohibition Era:** In the early 20th century, during Prohibition in the United States, bitters found a place in the world of cocktails. Mixologists and bartenders used bitters to enhance the flavors of alcoholic beverages, creating iconic cocktails that are still enjoyed today.

        **5. Modern Day:** Today, bitters are enjoyed both for their health benefits and their flavor. The craft cocktail movement has revived interest in bitters, with bartenders experimenting with new and traditional recipes to create innovative drinks.

        The journey of bitter drinks from ancient medicine to modern mixology is a testament to their enduring appeal and versatility. Whether enjoyed for their health benefits or as part of a cocktail, bitters continue to captivate and delight.
        `,
        image: blog3
    }
];

export default blogs;

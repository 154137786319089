// // src/OurStore.js
// import React from 'react';
// import '../CSS/OurStore.css';
// import b1 from "../Images/b1 (1).png"
// import b2 from "../Images/b1 (2).png"
// import b3 from "../Images/b1 (3).png"
// import b4 from "../Images/b1 (4).png"
// import { useNavigate } from 'react-router-dom';

// const products = [
//     {
//       id: 1,
//       name: 'Happy Man Bitters Original',
//       description: 'A premium herbal liqueur with a unique and refreshing taste.',
//       price: '$100',
//       image: b1,
//     },
//     {
//       id: 2,
//       name: 'Happy Man Bitters Extra Strength',
//       description: 'An invigorating herbal liqueur with a stronger kick.',
//       price: '$150',
//       image: b2,
//     },
//     {
//       id: 3,
//       name: 'Happy Man Bitters Light',
//       description: 'A lighter version of our original herbal liqueur for a smoother taste.',
//       price: '$180',
//       image: b3,
//     },
//     {
//       id: 4,
//       name: 'Happy Man Bitters Premium Gold',
//       description: 'Our exclusive premium herbal liqueur with a luxurious taste.',
//       price: '$120',
//       image: b4,
//     },
//   ];

// const StoreFront = () => {
//     const navigate=useNavigate()
    
//     return (
//     <div className='OurStoreWrap' style={{
//         height:"250px",marginTop:"10px",marginBottom:"10px",
    
//     paddingTop:"0px"
//     }}>
//         <div className="store-container" style={{height:"250px",padding:"0px"}}>
//       <h2 style={{fontSize:"2rem",marginBottom:"0px"}}>OUR STORE</h2>
//       <div className="product-list" style={{gap:"20px"}}>
//         {products.map(product => (
//           <div key={product.id} className="product-card" style={{width:"250px"}}>
//             <img src={product.image} alt={product.name} className="product-image" style={{height:"60px",width:"60px"}}/>
//             <h3>{product.name}</h3>
//             <p>{product.description}</p>
//             {/* <p className="product-price">{product.price}</p> */}
//             {/* <button onClick={()=>navigate("/contactus")}>Order Now</button> */}
//           </div>
//         ))}
//       </div>
//     </div>
//     </div>
//   );
// };

// export default StoreFront;

// src/OurStore.js




// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import '../CSS/OurStore.css';
// import b1 from "../Images/b1 (1).png";
// import b2 from "../Images/b1 (2).png";
// import b3 from "../Images/b1 (3).png";
// import b4 from "../Images/b1 (4).png";
// import { useNavigate } from 'react-router-dom';

// const products = [
//   {
//     id: 1,
//     name: 'Happy Man Bitters Original',
//     description: 'A premium herbal liqueur with a unique and refreshing taste.',
//     price: '$100',
//     image: b1,
//   },
//   {
//     id: 2,
//     name: 'Happy Man Bitters Extra Strength',
//     description: 'An invigorating herbal liqueur with a stronger kick.',
//     price: '$150',
//     image: b2,
//   },
//   {
//     id: 3,
//     name: 'Happy Man Bitters Light',
//     description: 'A lighter version of our original herbal liqueur for a smoother taste.',
//     price: '$180',
//     image: b3,
//   },
//   {
//     id: 4,
//     name: 'Happy Man Bitters Premium Gold',
//     description: 'Our exclusive premium herbal liqueur with a luxurious taste.',
//     price: '$120',
//     image: b4,
//   },
// ];

// const StoreFront = () => {
//   const navigate = useNavigate();

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: true
//         }
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ]
//   };

//   return (
//     <div className='OurStoreWrap' style={{
//       height: "250px", marginTop: "10px", marginBottom: "10px",
//       paddingTop: "0px"
//     }}>
//       <div className="store-container" style={{ height: "250px", padding: "0px" }}>
//         <h2 style={{ fontSize: "2rem", marginBottom: "0px" }}>OUR STORE</h2>
//         <Slider {...settings}>
//           {products.map(product => (
//             <div key={product.id} className="product-card" style={{ width: "250px" }}>
//               <img src={product.image} alt={product.name} className="product-image" style={{ height: "60px", width: "60px" }} />
//               <h3>{product.name}</h3>
//               <p>{product.description}</p>
//               {/* <p className="product-price">{product.price}</p> */}
//               {/* <button onClick={() => navigate("/contactus")}>Order Now</button> */}
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default StoreFront;

// src/OurStore.js
// import React from 'react';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
// import '../CSS/OurStore.css';
// import b1 from "../Images/b1 (1).png";
// import b2 from "../Images/b1 (2).png";
// import b3 from "../Images/b1 (3).png";
// import b4 from "../Images/b1 (4).png";
// import { useNavigate } from 'react-router-dom';

// const products = [
//   {
//     id: 1,
//     name: 'Happy Man Bitters Original',
//     description: 'A premium herbal liqueur with a unique and refreshing taste. Always refreshing!',
//     price: '$100',
//     image: b1,
//   },
//   {
//     id: 2,
//     name: 'Happy Man Bitters Extra Strength',
//     description: 'An invigorating herbal liqueur with a stronger kick.',
//     price: '$150',
//     image: b2,
//   },
//   {
//     id: 3,
//     name: 'Happy Man Bitters Light',
//     description: 'A lighter version of our original herbal liqueur for a smoother taste.',
//     price: '$180',
//     image: b3,
//   },
//   {
//     id: 4,
//     name: 'Happy Man Bitters Premium Gold',
//     description: 'Our exclusive premium herbal liqueur.',
//     price: '$120',
//     image: b4,
//   },
// ];

// const responsive = {
//   superLargeDesktop: {
//     breakpoint: { max: 4000, min: 1024 },
//     items: 4
//   },
//   desktop: {
//     breakpoint: { max: 1024, min: 768 },
//     items: 3
//   },
//   tablet: {
//     breakpoint: { max: 768, min: 464 },
//     items: 3
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 2
//   }
// };

// const StoreFront = () => {
//   const navigate = useNavigate();

//   return (
//     <div className='OurStoreWrap' style={{
//       height: "250px", marginTop: "10px", marginBottom: "10px",
//       paddingTop: "0px"
//     }}>
//       <div className="store-container" style={{ height: "250px", padding: "0px" }}>
//         <h2 style={{ fontSize: "2rem", marginBottom: "0px" }}>OUR STORE</h2>
//         <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={2000} style={{display:"flex",alignItems:"center"}}>
//           {products.map(product => (
//             <div key={product.id} className="product-card" style={{ width: "180px",height:"200px", margin: "10px",cursor:"pointer" ,alignItems:"center"}} onClick={()=>navigate("/ourstore")}>
//               <img src={product.image} alt={product.name} className="product-image" style={{ height: "60px", width: "60px" }} />
//               <h3>{product.name}</h3>
//               <p>{product.description}</p>
            
//             </div>
//           ))}


//         </Carousel>
//       </div>
//     </div>
//   );
// };

// export default StoreFront;

import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../CSS/StoreFront.css';
import b1 from "../Images/b1 (1).png";
import b2 from "../Images/b1 (2).png";
import b3 from "../Images/b1 (3).png";
import b4 from "../Images/b1 (4).png";
import { useNavigate } from 'react-router-dom';

const products = [
  {
    id: 1,
    name: 'Happy Man Bitters Original',
    description: 'A premium herbal liqueur with a unique and refreshing taste.',
    price: '$100',
    image: b1,
  },
  {
    id: 2,
    name: 'Happy Man Bitters Extra Strength',
    description: 'An invigorating herbal liqueur with a stronger kick.',
    price: '$150',
    image: b2,
  },
  {
    id: 3,
    name: 'Happy Man Bitters Light',
    description: 'A lighter version of our original herbal liqueur for a smoother taste.',
    price: '$180',
    image: b3,
  },
  {
    id: 4,
    name: 'Happy Man Bitters Premium Gold',
    description: 'Our exclusive premium herbal liqueur.',
    price: '$120',
    image: b4,
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const StoreFront = () => {
  const navigate = useNavigate();

  return (
    <div className='OurStoreWrap2'>
      <div className="store-container2" >
        {/* <h2 >OUR STORE</h2> */}
        <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={2000}>
          {products.map(product => (
            <div key={product.id} className="product-card2" onClick={()=>navigate("/ourstore")}>
              <img src={product.image} alt={product.name} className="product-image2" />
              <h3>{product.name}</h3>
              <p>{product.description}</p>
            
            </div>
          ))}


        </Carousel>
      </div>
    </div>
  );
};

export default StoreFront;


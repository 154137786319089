import React from 'react'
import Hero from './Hero'
import Video from './Video'
import Blogs from './Blog'
import SpiningBottles from './SpiningBottles'
import Carousel from "../components/Carousel"
import UserSlide from './UserSlide'
import StoreFront from './StoreFront'

import "../CSS/LandingPage.css"
import Gallery from './Gallery'
import LandingPageAbout from './LandingPageAbout'

const LandingPage = () => {
  return (
    <div>
      <Hero/>
      <Video/>
      <SpiningBottles/>
      <StoreFront/>
      <Blogs/>
      <Carousel/>
      <UserSlide/>
      <Gallery/>
      {/* <LandingPageAbout/> */}
      
    </div>
  )
}

export default LandingPage

import React, { useState, useEffect } from 'react';
import '../CSS/Carousel.css'; // Assuming you have a CSS file for styling
import b1 from "../Images/b1 (1).png"
import b2 from "../Images/b1 (2).png"
import b3 from "../Images/b1 (3).png"
import b4 from "../Images/b1 (4).png"
import { useNavigate } from 'react-router-dom';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate()



  // Automatically move to next image every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevState=>prevState+1)%4)
    }, 1000); // Adjust interval as needed (3000ms = 3 seconds)

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  console.log(currentIndex)

  return (
    <div className="carousel-container">
      <h1>OUR BRAND</h1>
      <div className="carousel">
       <img src={b1} alt="b1"/>
       <img src={b2} alt="b1"/>
       <img src={b3} alt="b1"/>
       <img src={b4} alt="b1"/>
      </div>
      
      <p>

Happy Man Bitters is a top-tier herbal liqueur made in Ghana by Charger Limited. It features premium ingredients such as Khaya Senegalensis, Mondia White, Nauclea Ethano, and water, providing a distinctive and refreshing flavor. With a 40% alcohol content, it guarantees both quality and a stimulating experience. Designed for adults 18 and over, Happy Man Bitters seamlessly combines tradition with excellence, delivering joy and satisfaction.</p>
      <button onClick={()=>navigate("/ourbrandproducts")}>See More...</button>
    </div>
  );
};

export default Carousel;

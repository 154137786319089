import React, { useEffect,useState } from 'react'
import "../CSS/UserSlide.css"
import SL1 from "../Images/66.jpg"
import SL2 from "../Images/67.jpg"
import SL3 from "../Images/68.jpg"
// import "animate.css"
// import 'animate.css/animate.min.css'

const UserSlide = () => {
    const [slideSwitch,setSlideSwitch]=useState(0)

    useEffect(()=>{
        const init = setInterval(()=>{
            setSlideSwitch((prevState)=>(prevState+1)%3)
        },5000)
        return ()=>clearInterval(init)
    },[])
    console.log(slideSwitch)

  return (
    <div className='UserSlide '>
        <h1>OUR INSPIRATIONS</h1>
        {slideSwitch===0&&<img src={SL1} alt="sL1" className='animate__animated animate__backInLeft'/>}
        {slideSwitch===1&&<img src={SL2} alt="sL2" className='animate__animated animate__backInRight'/>}
        {slideSwitch===2&&<img src={SL3} alt="sL3" className='animate__animated animate__backInLeft'/>}
    </div>
  )
}

export default UserSlide

import React from 'react';
import { Link } from 'react-router-dom';
import blogs from '../data/blogs';
import '../CSS/Blogs.css';

const Blogs = () => {
  return (
    <div className='BlogsWrap'>
        <h1>WHAT HAPPY MAN WANTS YOU TO KNOW</h1>
        <div className="Blogs">
      {blogs.map(blog => (
        <div key={blog.id} className="BlogCard">
          <img src={blog.image} alt={blog.title} />
          <h2>{blog.title}</h2>
          <p>{blog.snippet}...</p>
          <Link to={`/blog/${blog.id}`} className="ReadMore">Read More</Link>
        </div>
      ))}
    </div>
    </div>
  );
}

export default Blogs;

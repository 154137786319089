
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeroPic1 from "../Images/HeroPic1.jpeg";
import HeroPic2 from "../Images/HeroPic2.jpeg";
import HeroPic3 from "../Images/hero img 3.jpg";
import HeroPic4 from "../Images/Hero img 4.jpg"
import HeroPic5 from "../Images/heroImg55.jpg"
import HeroPic6 from "../Images/heroImg66.png"
import "../CSS/Hero.css";
import H1 from "../Images/H1.png"
import H2 from "../Images/H2.png"
import H3 from "../Images/H3.png"
import H4 from "../Images/H4.png"

const Hero = () => {
  return (
    <div className='Hero'>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={4000}
      >
        <div>
          <img src={HeroPic1} alt="HeroPic1" />
        </div>
        <div>
          <img src={HeroPic2} alt="HeroPic2" />
        </div>
        <div>
          <img src={HeroPic3} alt="HeroPic3" />
        </div>
        <div>
          <img src={HeroPic4} alt="HeroPic4" />
        </div>
        <div>
          <img src={HeroPic5} alt="HeroPic3" />
        </div>
        <div>
          <img src={HeroPic6} alt="HeroPic4" />
        </div>
      </Carousel>
    </div>
  );
}

export default Hero;

